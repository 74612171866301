import { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EVENT_TYPE } from "../../../constants";
import { actions, Leader, RootState, ThunkDispatchType } from "../../../store";
import { getLeaderTemplate } from "../../../store/templates";
import { trackEvent } from "../../../utils/appAnalyticsUtils";
import ExecutiveCalendarStep from "./ExecutiveCalendarStep";

export interface ExecutiveCalendarStepContainerProps {
  onFinish: () => void;
  buttonText?: string;
}

const ExecutiveCalendarStepContainer = ({
  onFinish, buttonText
}: ExecutiveCalendarStepContainerProps): ReactElement | null => {

  const user = useSelector((state: RootState) => state.auth.user);
  const leaders = useSelector((state: RootState) => state.leaders);
  const schedule = useSelector((state: RootState) => state.schedule);
  const dispatch = useDispatch<ThunkDispatchType>();

  useEffect(() => {
    dispatch(actions.leaders.fetchLeaders());
    dispatch(actions.schedule.fetchRemoteCalendars());
    dispatch(actions.schedule.fetchCalendars());
  }, [dispatch]);

  const handleGetEmailCalendars = async (email: string) => {
    const emailCalendars = await dispatch(actions.schedule.addDelegateCalendarsForEmails([email]));
    return emailCalendars[email];
  };

  const handleAddEditExec = async (leader: {
    id?: Leader['id'], firstName: string, lastName: string, email: string,
    calendars: { id: number; associate: boolean }[]
  }) => {
    const providerLookup = schedule.calendars
      .map(c => ({ [c.id]: c.provider }))
      .reduce((a, b) => ({ ...a, ...b }), {});

    let newLeader: Leader;
    if (leader.id) {
      const fullLeader = {
        id: leader.id,
        first_name: leader.firstName,
        last_name: leader.lastName,
        email: leader.email,
      };

      const ldr = await dispatch(actions.leaders.updateLeader(fullLeader, null, null));
      if (ldr) {
        newLeader = ldr;
      } else { // something went wrong
        return;
      }
    } else {
      const fullLeader = {
        ...getLeaderTemplate(),
        first_name: leader.firstName,
        last_name: leader.lastName,
        email: leader.email,
      };

      const ldr = await dispatch(actions.leaders.createLeader(fullLeader, null, null));
      if (!ldr) return; // something went wrong

      newLeader = ldr;
    }

    const leaderToCalendars = leader.calendars.map(cal => {
      return {
        leader_id: newLeader.id,
        provider: providerLookup[cal.id],
        calId: cal.id,
        associate: cal.associate,
        prevent_conflict: true,
        allow_calendar_analytics: true,
        allow_crm: true
      };
    });

    
    if (leaderToCalendars.length === 0) {
      trackEvent(EVENT_TYPE.ONBOARD_SKIP_CALENDARS_EXEC);
    } else {
      await dispatch(actions.schedule.updateLeaderCalendars(leaderToCalendars));
    }
  };

  const handleDeleteExec = async (id: Leader['id']) => {
    await dispatch(actions.leaders.deleteLeader(id));
  };

  const handleFinish = async () => {
    if (user?.profile.user_leader) {
      const leaderIds = leaders.leaders.map(leader => leader.id);
      const noUserLeader = leaderIds.filter(id => (id !== user.profile.user_leader && id !== -1));
      await(dispatch(actions.leaders.reorderLeaders([...noUserLeader, user.profile.user_leader])));
    }

    await dispatch(actions.auth.loadUser());
    onFinish();
  };

  if (!user) return null;

  return <ExecutiveCalendarStep
    user={user}
    leaders={leaders.leaders}
    calendars={schedule.calendars}
    onGetEmailCalendars={handleGetEmailCalendars}
    onAddEditExec={handleAddEditExec}
    onDeleteExec={handleDeleteExec}
    onFinish={handleFinish}
    oauth_grant_details={user?.oauth_grant_details || {}}
    buttonText={buttonText}
  />;
};

export default ExecutiveCalendarStepContainer;
